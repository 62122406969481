import React, { CSSProperties, useEffect, useRef, useState } from "react"
import * as Accordion from "@radix-ui/react-accordion"
import { graphql, useStaticQuery } from "gatsby"
import { FixedSizeGrid as Grid } from "react-window"
import { AutoSizer } from "react-virtualized"
import { useRecoilState, useRecoilValue } from "recoil"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import {
  allCurrentOreCardsState,
  allOreCardsCurrentCardState,
  allOreCardsCurrentFiltersCountState,
  allOreCardsCurrentFilterState,
  allOreCardsCurrentTypeState,
  allOreCardsDefaultTraitState,
  allOreCardsSearchState,
  allOreCardsSortState,
  allOreCardsTraitsState,
  Type,
} from "../state/all-cards"
import Logo from "../images/svg/logo.svg"
import IconMagnifier from "../images/svg/magnifier.svg"
import IconCross from "../images/svg/cross.svg"
import IconFilter from "../images/svg/filter.svg"
import AccordionItem from "../components/filter/accordion-item"
import CardItem from "../components/filter/card-item"
import useTailwindScreen from "../hooks/use-tailwind-screen"
import CardTypeSwitch from "./../components/filter/card-type-switch"
import BodyClassName from "react-body-classname"

const AllCards: React.FC = () => {
  const { screenSizes } = useStaticQuery(
    graphql`
      query {
        screenSizes: allTailwindScreenSizes(
          sort: { fields: width, order: DESC }
        ) {
          nodes {
            size
            width
          }
        }
      }
    `
  )

  const cards = useRecoilValue(allCurrentOreCardsState)
  const traits = useRecoilValue(allOreCardsTraitsState)
  const filtersCount = useRecoilValue(allOreCardsCurrentFiltersCountState)
  const [currentCard, setCurrentCard] = useRecoilState(
    allOreCardsCurrentCardState
  )
  const [currentType, setCurrentType] = useRecoilState(
    allOreCardsCurrentTypeState
  )
  const [currentFilter, setCurrentFilter] = useRecoilState(
    allOreCardsCurrentFilterState
  )
  const [search, setSearch] = useRecoilState(allOreCardsSearchState)
  const [sort, setSort] = useRecoilState(allOreCardsSortState)
  const defaultTraitState = useRecoilValue(allOreCardsDefaultTraitState)
  const [accordionTrait, setAccordionTrait] = useState("")

  const screens: { [key: string]: string } = {}
  screenSizes.nodes.forEach(
    ({ size, width }: { size: string; width: number }) =>
      (screens[size] = `${width}px`)
  )
  const screen = useTailwindScreen(screens) as
    | "_"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"

  const gridDefaults = {
    _: {
      cols: 2,
    },
    sm: {
      cols: 2,
    },
    md: {
      cols: 3,
    },
    lg: {
      cols: 3,
    },
    xl: {
      cols: 4,
    },
    "2xl": {
      cols: 5,
    },
  }

  const gridRef = useRef<Grid<any>>(null)

  const handleType = (type: Type) => {
    setCurrentType(type)
    scrollGridToTop()
  }

  useEffect(() => {
    // We need to set the default trait state with a short delay, otherwise the component will not render properly
    setTimeout(() => {
      setAccordionTrait(defaultTraitState)
    }, 50)
  }, [])

  useEffect(() => {
    setAccordionTrait(defaultTraitState)
  }, [currentType])

  const handleAccordionTrait = (accordionTrait: string) => {
    setAccordionTrait(accordionTrait)
  }

  const [showFilter, setShowFilter] = useState(false)
  const [searchFocused, setSearchFocused] = useState(false)

  const handleSearch = ({
    currentTarget: { value },
  }: React.FormEvent<HTMLInputElement>) => setSearch(value)

  const handleClear = () => {
    handleClearSearch()
    handleClearFilter()
  }

  const handleClearSearch = () => setSearch("")

  useEffect(() => {
    if (!currentCard) {
      return
    }

    setTimeout(() => {
      const currentCardIndex = cards.findIndex(
        card => card.name === currentCard
      )

      if (currentCardIndex > -1) {
        gridRef.current?.scrollToItem({
          columnIndex: currentCardIndex % gridDefaults[screen].cols,
          rowIndex: Math.round(currentCardIndex / gridDefaults[screen].cols),
        })
      }
    }, 250)
  }, [currentCard, gridRef])

  const handleFilter =
    (trait: string, value: string) =>
    ({ currentTarget: { checked } }: React.FormEvent<HTMLInputElement>) => {
      const newFilter = new Map(currentFilter)
      const newValues = new Set(currentFilter.get(trait) || [])

      if (checked) {
        newValues.add(value)
      } else {
        newValues.delete(value)
      }

      if (!newValues.size) {
        newFilter.delete(trait)
      } else {
        newFilter.set(trait, newValues)
      }

      setCurrentFilter(newFilter)
      scrollGridToTop()
    }

  const handleRangeFilter = (
    trait: string,
    minValue: number | null,
    maxValue: number | null
  ) => {
    const newFilter = new Map(currentFilter)

    if (minValue === null || maxValue === null) {
      newFilter.delete(trait)
    } else {
      newFilter.set(trait, new Set([`${minValue}`, `${maxValue}`]))
    }

    setCurrentFilter(newFilter)
    scrollGridToTop()
  }

  const handleSelectAll = (trait: string, values: string[]) => {
    const newFilter = new Map(currentFilter)
    const newValues = new Set(values)

    newFilter.set(trait, newValues)
    setCurrentFilter(newFilter)
    scrollGridToTop()
  }

  const handleUnselectAll = (trait: string) => {
    const newFilter = new Map(currentFilter)

    newFilter.delete(trait)
    setCurrentFilter(newFilter)
    scrollGridToTop()
  }

  const handleClearFilter = () => {
    setCurrentFilter(new Map())
    setShowFilter(false)
    scrollGridToTop()
  }

  const handleShowFilter = () => setShowFilter(!showFilter)

  const handleSearchFocussed = () => setSearchFocused(true)
  const handleSearchBlurred = () => setSearchFocused(false)

  const handleUnsortBy = () => {
    setSort("")
    scrollGridToTop()
  }

  const scrollGridToTop = () => {
    gridRef.current?.scrollToItem({
      columnIndex: 0,
      rowIndex: 0,
    })
  }

  const handleNextCard = (currentIndex: number) => () => {
    if (currentIndex >= cards.length) {
      return
    }

    setCurrentCard(cards[currentIndex + 1].name)
  }

  const handlePrevCard = (currentIndex: number) => () => {
    if (currentIndex <= 0) {
      return
    }

    setCurrentCard(cards[currentIndex - 1].name)
  }

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties
  }) => {
    const { cols } = gridDefaults[screen]
    const index = rowIndex * cols + columnIndex

    if (!cards[index]) {
      return null
    }

    return (
      <div style={style}>
        <CardItem
          card={cards[index]}
          onPrevCard={handlePrevCard(index)}
          onNextCard={handleNextCard(index)}
        />
      </div>
    )
  }

  return (
    <BodyClassName className={showFilter ? "overflow-hidden" : ""}>
      <Layout
        bgColor="black"
        footerClassName="lg:pl-[min(calc(500px_+_0.875rem),calc(3/12*100%_+_0.875rem))] [&>div:first-child]:lg:hidden lg:mb-4 [&>nav]:lg:flex-col"
      >
        <SEO title="All Cards" />

        <section className="font-mono relative flex flex-1 flex-col px-3.5 uppercase">
          <header className="fixed top-0 left-3.5 right-3.5 z-10 flex h-[90px] flex-row items-center justify-between border-b border-red/40 bg-black lg:left-0 lg:right-0 lg:h-auto lg:border-0 lg:bg-transparent">
            <Link to="/">
              <Logo className="h-[90px] w-[90px] animate-spin-y text-white lg:h-[140px] lg:w-[140px]" />
            </Link>
            <div className="flex pr-4 lg:hidden">
              <button
                onClick={handleShowFilter}
                className={`${
                  !showFilter
                    ? "fixed bottom-5 right-5 flex h-[56px] w-[56px] items-center justify-center rounded-[10px] bg-[rgba(255,255,255,0.5)] text-white shadow-xl backdrop-blur-lg"
                    : ""
                }`}
              >
                <IconFilter
                  className={`h-[30px] w-[30px] ${!showFilter ? "" : "hidden"}`}
                />
              </button>
            </div>
          </header>
          <div className="no-scrollbar relative mt-[100px] flex flex-1 flex-row gap-4 overflow-auto overscroll-contain lg:mt-[140px]">
            <div className="hidden w-3/12 max-w-[500px] shrink-0 lg:invisible lg:block" />
            <div
              className={`${
                showFilter
                  ? "fixed left-0 right-0 bottom-0 top-[90px] z-50 flex h-[calc(100%-90px)] w-full px-3.5 pb-[71px]"
                  : "hidden"
              } no-scrollbar left-0 box-border flex-col gap-4 overflow-auto overscroll-contain bg-black lg:fixed lg:top-[140px] lg:left-3.5 lg:right-auto lg:bottom-0 lg:flex lg:h-[calc(100vh-140px)] lg:w-3/12 lg:max-w-[500px] lg:gap-0 lg:pr-3.5 lg:pb-[90px]`}
            >
              <div className="flex flex-col gap-8 bg-black pt-4 lg:sticky lg:top-0 lg:z-10 lg:pt-0">
                <div className="inline-flex">
                  <CardTypeSwitch onClick={handleType} />
                </div>
                <div
                  className={`mt-[10px] flex flex-row items-center gap-2 border-b pb-4 ${
                    search || searchFocused ? "border-red" : "border-red/40"
                  }`}
                >
                  <IconMagnifier className="h-[14px] w-[14px]" />
                  <input
                    className="w-full appearance-none bg-transparent uppercase text-white focus:outline-none"
                    type="text"
                    placeholder="Search for name..."
                    onChange={handleSearch}
                    onFocus={handleSearchFocussed}
                    onBlur={handleSearchBlurred}
                    value={search}
                  />
                </div>
              </div>

              <Accordion.Root
                className={`relative flex flex-col space-y-4 pt-4 ${
                  search ? "pointer-events-none" : ""
                }`}
                type="single"
                value={accordionTrait}
                onValueChange={handleAccordionTrait}
                collapsible
              >
                {[...traits].map(([trait, values]) => (
                  <Accordion.Item
                    key={trait}
                    value={trait}
                    className="group flex flex-col space-y-2 border-b border-red/40 pb-4 text-white/60 hover:border-red"
                  >
                    <AccordionItem
                      trait={trait}
                      values={values}
                      onFilter={handleFilter}
                      onRangeFilter={handleRangeFilter}
                      onSelectAll={handleSelectAll}
                      onUnselectAll={handleUnselectAll}
                    />
                  </Accordion.Item>
                ))}
                {search && (
                  <div className="pointer-events-none absolute inset-0 top-px h-full w-full bg-black/50" />
                )}
              </Accordion.Root>

              <div className="fixed left-0 right-0 bottom-0 top-auto z-50 flex w-full flex-row justify-around bg-red lg:hidden">
                <button
                  className="bg-red px-3 py-4 text-white"
                  onClick={handleClearFilter}
                >
                  Clear All
                </button>
                <button
                  className="bg-red px-3 py-4 text-white"
                  onClick={handleShowFilter}
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="flex w-full grow flex-col gap-4 lg:w-9/12">
              <div className="hidden min-h-[44px] max-w-[calc(100%-0.875rem)] flex-row items-center gap-x-4 whitespace-nowrap bg-black lg:flex">
                <span>
                  {cards.length.toLocaleString()} Item
                  {cards.length !== 1 ? "s" : ""}
                </span>
                <span className="h-[6px] w-[6px] rounded-full bg-red" />
                {sort.length ? (
                  <>
                    <span>Sorted By</span>
                    <label className="flew-row flex cursor-pointer items-center space-x-4 rounded-[3px] bg-red/20 px-3 py-1.5 hover:bg-red">
                      <input
                        type="checkbox"
                        checked
                        hidden
                        onChange={handleUnsortBy}
                        className="hidden"
                      />
                      <span>{sort}</span>
                      <IconCross className="h-[12px] w-[12px]" />
                    </label>
                    <span className="h-[6px] w-[6px] rounded-full bg-red" />
                  </>
                ) : null}
                <span className="flex flex-row items-center space-x-2">
                  <span>Filter</span>
                  <span className="flex h-[18px] min-w-[18px] items-center justify-center rounded-[2px] bg-[#D9D9D9] p-0.5 text-xs leading-none text-black">
                    {filtersCount}
                  </span>
                </span>
                <ol className="no-scrollbar flex max-w-full flex-row space-x-2 overflow-x-auto overscroll-contain">
                  {[...currentFilter].map(([trait, values]) =>
                    trait.toUpperCase() === "RARITY SCORE" ? (
                      <li key={trait}>
                        <label className="flew-row flex cursor-pointer items-center space-x-4 rounded-[3px] bg-red/20 px-3 py-1.5 hover:bg-red">
                          <input
                            type="checkbox"
                            checked
                            hidden
                            onChange={() =>
                              handleRangeFilter(trait, null, null)
                            }
                            className="hidden"
                          />
                          <span>
                            {trait}: {[...values][0]} - {[...values][1]}
                          </span>
                          <IconCross className="h-[12px] w-[12px]" />
                        </label>
                      </li>
                    ) : (
                      [...values].map(value => (
                        <li key={`${trait}-${value}`}>
                          <label className="flew-row flex cursor-pointer items-center space-x-4 rounded-[3px] bg-red/20 px-3 py-1.5 hover:bg-red">
                            <input
                              type="checkbox"
                              checked
                              hidden
                              onChange={handleFilter(trait, value)}
                              className="hidden"
                            />
                            <span>
                              {trait}: {value}
                            </span>
                            <IconCross className="h-[12px] w-[12px]" />
                          </label>
                        </li>
                      ))
                    )
                  )}
                </ol>
              </div>

              <div className="h-[calc(100vh-90px)] w-full lg:h-[calc(100vh-140px-60px)]">
                {cards.length > 0 ? (
                  <AutoSizer>
                    {({ height, width }) => (
                      <Grid
                        ref={gridRef}
                        columnCount={gridDefaults[screen].cols}
                        columnWidth={width / gridDefaults[screen].cols}
                        height={height}
                        rowCount={Math.ceil(
                          cards.length / gridDefaults[screen].cols
                        )}
                        rowHeight={
                          currentType === "pfps"
                            ? width / gridDefaults[screen].cols
                            : (width / gridDefaults[screen].cols) * (687 / 550)
                        }
                        width={width}
                      >
                        {Cell}
                      </Grid>
                    )}
                  </AutoSizer>
                ) : null}
                {!cards.length ? (
                  <div className="flex h-full w-full flex-col items-center justify-center space-y-3 lg:text-2xl">
                    <div>No Results found</div>
                    <button
                      className="truncate whitespace-nowrap rounded-[3px] bg-red/60 py-1.5 px-3 uppercase transition-colors duration-150 ease-in-out"
                      onClick={handleClear}
                    >
                      Clear Filters
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </BodyClassName>
  )
}

export default AllCards
