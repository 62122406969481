import React from "react"
import { allOreCardsCurrentCardState, Card } from "../../state/all-cards"
import { Dialog, DialogTrigger, DialogContent } from "./dialog"
import { useRecoilState } from "recoil"

type Props = {
  card: Card
  onNextCard: () => void
  onPrevCard: () => void
}

const CardItem: React.FC<Props> = ({ card, onNextCard, onPrevCard }) => {
  const [currentCard, setCurrentCard] = useRecoilState(
    allOreCardsCurrentCardState
  )

  if (!card.files.length) {
    return null
  }

  const onOpenChange = (open: boolean, cardName: string) => {
    setCurrentCard(open ? cardName : null)
  }

  const image = card.files.find(file => file.mimeType === "image/jpeg")

  if (!image) {
    return null
  }

  return (
    <div
      className={`${
        card.type === "ogs" ? "aspect-[550/687]" : "aspect-square"
      } p-1 lg:p-2`}
    >
      <Dialog
        defaultOpen={card.name === currentCard}
        onOpenChange={open => {
          onOpenChange(open, card.name)
        }}
      >
        <DialogTrigger className="h-full w-full !outline-none focus-visible:ring-1 focus-visible:ring-lonestar">
          <div
            className="relative h-full w-full"
            style={{
              backgroundColor: `rgb(${image.dominantColor.r}, ${image.dominantColor.g}, ${image.dominantColor.b})`,
            }}
          >
            <img
              className="absolute inset-0 h-full w-full object-cover object-center"
              src={image.url}
              alt={card.name}
            />
          </div>
        </DialogTrigger>
        {card.name === currentCard ? (
          <DialogContent
            card={card}
            onNextCard={onNextCard}
            onPrevCard={onPrevCard}
          />
        ) : null}
      </Dialog>
    </div>
  )
}

export default CardItem
