import React, { useEffect, useState } from "react"
import * as Accordion from "@radix-ui/react-accordion"
import MultiRangeSlider from "multi-range-slider-react"
import IconMagnifier from "../../images/svg/magnifier.svg"
import IconExpanded from "../../images/svg/expanded.svg"
import IconCollapsed from "../../images/svg/collapsed.svg"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  allOreCardsCurrentFilterState,
  allOreCardsPFPsRarityScoreMaxValue,
  allOreCardsPFPsRarityScoreMinValue,
  allOreCardsSortState,
} from "../../state/all-cards"

type Props = {
  trait: string
  values: Map<string, number>
  onFilter: (
    trait: string,
    value: string
  ) => (e: React.FormEvent<HTMLInputElement>) => void
  onRangeFilter: (
    trait: string,
    minValue: number | null,
    maxValue: number | null
  ) => void
  onSelectAll: (trait: string, values: string[]) => void
  onUnselectAll: (trait: string) => void
}

let timeout: ReturnType<typeof setTimeout>

const AccordionItem: React.FC<Props> = ({
  trait,
  values,
  onFilter,
  onRangeFilter,
  onSelectAll,
  onUnselectAll,
}) => {
  const currentFilter = useRecoilValue(allOreCardsCurrentFilterState)
  const minRarityScore = useRecoilValue(allOreCardsPFPsRarityScoreMinValue)
  const maxRarityScore = useRecoilValue(allOreCardsPFPsRarityScoreMaxValue)
  const [search, setSearch] = useState("")
  const [allSelected, setAllSelected] = useState(false)
  const [sort, setSort] = useRecoilState(allOreCardsSortState)

  useEffect(() => {
    if (!currentFilter.has(trait)) {
      setAllSelected(false)

      return
    }

    setAllSelected((currentFilter.get(trait)?.size || 0) > 0)
  }, [currentFilter, values, trait])

  const handleSearch = ({
    currentTarget: { value },
  }: React.FormEvent<HTMLInputElement>) => setSearch(value)

  const handleSelectAll = () => {
    const plainValues = [...values]
      .filter(
        ([value]) => value.toUpperCase().indexOf(search.toUpperCase()) !== -1
      )
      .map(([value]) => value)
    onSelectAll(trait, plainValues)
  }

  const handleUnselectAll = () => onUnselectAll(trait)

  const handleSortBy = () => setSort(trait)
  const handleUnsortBy = () => setSort("")

  let filterSize = currentFilter.get(trait)?.size
  let content = (
    <Accordion.Content>
      {values.size > 4 ? (
        <div className="flex flex-row items-center gap-2 pb-4">
          <IconMagnifier className="h-[14px] w-[14px]" />
          <input
            className="w-full appearance-none bg-transparent uppercase text-white focus:outline-none"
            type="text"
            placeholder="Search..."
            onChange={handleSearch}
            value={search}
          />
        </div>
      ) : null}
      <div className="border-t border-red/40 pt-4">
        <ol className="scrollbar-[4px] flex max-h-[145px] flex-col space-y-3 overflow-y-auto overscroll-contain pr-[10px] scrollbar-thumb-red scrollbar-track-red-light">
          {values.size > 1 ? (
            <div className="flex flex-row gap-2">
              {allSelected ? (
                <button
                  onClick={handleUnselectAll}
                  className="truncate whitespace-nowrap rounded-[3px] px-1.5 uppercase transition-colors duration-150 ease-in-out hover:bg-red/60"
                >
                  Deselect All
                </button>
              ) : (
                <button
                  onClick={handleSelectAll}
                  className="truncate whitespace-nowrap rounded-[3px] px-1.5 uppercase transition-colors duration-150 ease-in-out hover:bg-red/60"
                >
                  Select All
                </button>
              )}
              {sort === trait ? (
                <button
                  onClick={handleUnsortBy}
                  className="truncate whitespace-nowrap rounded-[3px] bg-red/60 px-1.5 uppercase transition-colors duration-150 ease-in-out"
                >
                  Unsort By
                </button>
              ) : (
                <button
                  onClick={handleSortBy}
                  className="truncate whitespace-nowrap rounded-[3px] px-1.5 uppercase transition-colors duration-150 ease-in-out hover:bg-red/60"
                >
                  Sort By
                </button>
              )}
            </div>
          ) : null}
          {[...values]
            .filter(
              ([value]) =>
                value.toUpperCase().indexOf(search.toUpperCase()) !== -1
            )
            .map(([value, count]) => (
              <li key={`${trait}-${value}`}>
                <label className="flex cursor-pointer flex-row items-center space-x-3 hover:text-white">
                  <input
                    type="checkbox"
                    onChange={onFilter(trait, value)}
                    checked={currentFilter.get(trait)?.has(value) || false}
                    className="peer h-[12px] w-[12px] shrink-0 appearance-none rounded-full bg-alto checked:bg-red hover:bg-white checked:hover:bg-red"
                  />
                  <span className="uppercase peer-checked:text-white">
                    {value} ({count})
                  </span>
                </label>
              </li>
            ))}
        </ol>
      </div>
    </Accordion.Content>
  )

  if (trait.toUpperCase() === "RARITY SCORE") {
    let currentMinValue: number | null = null
    let currentMaxValue: number | null = null

    if (currentFilter.get(trait)?.size === 2) {
      const [minValue, maxValue] = [...(currentFilter.get(trait) ?? [])]
      currentMinValue = parseFloat(minValue)
      currentMaxValue = parseFloat(maxValue)
    }

    const handleChange = ({
      minValue,
      maxValue,
      min,
      max,
    }: {
      minValue: number
      maxValue: number
      min: number
      max: number
    }) => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => {
        if (
          (currentMinValue || min) === minValue &&
          (currentMaxValue || max) === maxValue
        ) {
          return
        }

        if (minValue === min && maxValue === max) {
          onRangeFilter(trait, null, null)
          return
        }

        onRangeFilter(trait, minValue, maxValue)
      }, 100)
    }

    filterSize = 1
    content = (
      <Accordion.Content>
        <div className="flex flex-col space-y-3 pr-[10px]">
          <MultiRangeSlider
            min={minRarityScore}
            max={maxRarityScore}
            step={maxRarityScore / minRarityScore / 3}
            minValue={currentMinValue || minRarityScore}
            maxValue={currentMaxValue || maxRarityScore}
            onChange={handleChange}
            className="!border-0 !px-5 !shadow-none [&>div:first-child>:nth-child(6)>div>span]:!bg-red [&>div:first-child>:nth-child(4)]:bg-red [&>div:first-child>:nth-child(3)>div>span]:!bg-red"
          />
        </div>
      </Accordion.Content>
    )
  }

  return (
    <>
      <Accordion.Header>
        <Accordion.Trigger className="w-full appearance-none">
          <div className="flex cursor-pointer flex-row items-center justify-between accordion-expanded:pb-2">
            <div className="flex flex-row items-center space-x-3">
              <span className="flex items-center uppercase text-white before:mr-3 before:flex before:h-[18px] before:w-[18px] before:shrink-0 before:rounded-full before:bg-alto group-hover:text-white/100 before:group-hover:bg-white">
                {trait}
              </span>
              {currentFilter.has(trait) ? (
                <span className="flex h-[18px] min-w-[18px] items-center justify-center rounded-[2px] bg-[#D9D9D9] p-0.5 text-xs leading-none text-black">
                  {filterSize}
                </span>
              ) : null}
            </div>
            <IconExpanded className="hidden h-[12px] w-[12px] group-hover:text-white accordion-expanded:block" />
            <IconCollapsed className="block h-[12px] w-[12px] group-hover:text-white accordion-expanded:hidden" />
          </div>
        </Accordion.Trigger>
      </Accordion.Header>
      {content}
    </>
  )
}

export default AccordionItem
