import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import {
  allOreCardsCurrentTypeState,
  allOreCardsTypesState,
  Type,
} from "../../state/all-cards"

type Props = {
  onClick: (type: Type) => void
}

const CardTypeSwitch: React.FC<Props> = ({ onClick }) => {
  const currentType = useRecoilValue(allOreCardsCurrentTypeState)
  const types = useRecoilValue(allOreCardsTypesState)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (types.length === 1) {
    return null
  }

  if (!mounted) {
    return <div className="h-[44px]" />
  }

  return (
    <ol className="flex min-w-0 flex-row gap-2 overflow-hidden rounded-[3px]">
      {types.map(({ id, name }) => {
        return (
          <li key={id} className="flex min-w-0 shrink">
            <button
              className={`truncate whitespace-nowrap rounded-[3px] py-1.5 px-3 uppercase transition-colors duration-150 ease-in-out ${
                currentType === id ? "bg-red/60" : "hover:bg-red/60"
              }`}
              onClick={() => {
                onClick(id)
              }}
            >
              {name}
            </button>
          </li>
        )
      })}
    </ol>
  )
}

export default CardTypeSwitch
