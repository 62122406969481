import { useState, useEffect } from "react"

type Screens = {
  [key: string]: string
}

const getDeviceConfig = (screens: Screens, width: number) => {
  const normalizedScreens = Object.keys(screens)
    .map(screen => ({
      screen,
      width: parseInt(screens[screen], 10),
    }))
    .sort((a, b) => a.width - b.width)

  let currentScreen = "_"
  normalizedScreens.forEach(screen => {
    if (width >= screen.width) {
      currentScreen = screen.screen
    }
  })

  return currentScreen
}

const useTailwindScreen = (screens: Screens) => {
  const width = typeof window !== "undefined" ? window.innerWidth : 0
  const [screen, setScreen] = useState(() => getDeviceConfig(screens, width))

  useEffect(() => {
    const calcScreenSize = () => {
      const width = typeof window !== "undefined" ? window.innerWidth : 0
      setScreen(getDeviceConfig(screens, width))
    }

    window.addEventListener("resize", calcScreenSize)

    return () => window.removeEventListener("resize", calcScreenSize)
  }, [])

  return screen
}

export default useTailwindScreen
