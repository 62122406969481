import { atom, selector } from "recoil"
import allCardsData from "./all-cards-data.json"
import { urlSyncEffect } from "recoil-sync"
import { string, custom, nullable } from "@recoiljs/refine"

export type Traits = {
  [key: string]: string | null
}

export type Type = "pfps" | "ogs"

export type Types = Array<{
  id: Type
  name: string
}>

export type File = {
  name: string
  url: string
  mimeType: "image/jpeg" | "video/mp4"
  dominantColor: {
    r: number
    g: number
    b: number
  }
}

export type Card = {
  type: Type
  name: string
  files: File[]
  traits: Traits
  sort: {
    value: string | number
    type: "string" | "number"
    dir: "ASC" | "DESC"
  }
  links: {
    cnft: string
    jpgStore: string
  }
}

const allOreCardsState = atom<Card[]>({
  key: "AllOreCard",
  default: allCardsData as unknown as Card[],
})

const allOreCardsTypesState = selector<Types>({
  key: "AllOreCardsTypes",
  get: ({ get }) => {
    const allCards = get(allOreCardsState)
    const types: Types = []

    if (allCards.some(({ type }) => type === "ogs")) {
      types.push({ id: "ogs", name: "ORE ORE ORE OGs" })
    }

    if (allCards.some(({ type }) => type === "pfps")) {
      types.push({ id: "pfps", name: "OREMOB PFPs" })
    }

    return types
  },
})

const _allOreCardsCurrentTypeState = atom<Type>({
  key: "_AllOreCardsCurrentType",
  default: "ogs",
  effects: [
    urlSyncEffect({
      itemKey: "type",
      refine: custom(x => x as Type),
      history: "push",
    }),
  ],
})

const allOreCardsCurrentTypeState = selector<Type>({
  key: "AllOreCardsCurrentType",
  get: ({ get }) => get(_allOreCardsCurrentTypeState),
  set: ({ set }, type) => {
    set(_allOreCardsCurrentTypeState, type)
    set(allOreCardsCurrentFilterState, new Map())
    set(allOreCardsSortState, "")
    set(allOreCardsSearchState, "")
  },
})

const allOreCardsSearchState = atom<string>({
  key: "AllOreCardsSearch",
  default: "",
  effects: [
    urlSyncEffect({ itemKey: "search", refine: string(), history: "push" }),
  ],
})

const allOreCardsSortState = atom<string>({
  key: "AllOreCardsSort",
  default: "",
  effects: [
    urlSyncEffect({ itemKey: "sort", refine: string(), history: "push" }),
  ],
})

const allOreCardsCurrentFilterState = atom<Map<string, Set<string>>>({
  key: "AllOreCardsCurrentFilter",
  default: new Map(),
  effects: [
    urlSyncEffect({
      itemKey: "filter",
      refine: custom(x => x as Map<string, Set<string>>),
      history: "push",
    }),
  ],
})

const allOreCardsCurrentCardState = atom<string | null | undefined>({
  key: "AllOreCardsCurrentCardState",
  default: null,
  effects: [
    urlSyncEffect({
      itemKey: "name",
      refine: nullable(string()),
      history: "push",
    }),
  ],
})

const allOreCardsCurrentFiltersCountState = selector<number>({
  key: "AllOreCardsCurrentFiltersCount",
  get: ({ get }) => {
    const currentFilter = get(allOreCardsCurrentFilterState)

    if (!currentFilter.size) {
      return 0
    }

    return [...currentFilter].reduce(
      (previousValue: number, [trait, currentValue]) => {
        if (trait.toUpperCase() === "RARITY SCORE") {
          return previousValue + 1
        }

        return previousValue + currentValue.size
      },
      0
    )
  },
})

const allFilterableOreCardsState = selector<Card[]>({
  key: "AllFilterableOreCards",
  get: ({ get }) => {
    let allCards = get(allOreCardsState).filter(
      ({ type }) => type === get(allOreCardsCurrentTypeState)
    )

    const search = get(allOreCardsSearchState).trim()

    if (search) {
      allCards = allCards.filter(
        ({ name }) => name.toUpperCase().indexOf(search.toUpperCase()) !== -1
      )
    }

    return allCards
  },
})

const allOreCardsPFPsRarityScoreValues = selector<number[]>({
  key: "AllOreCardsPFPsRarityScoreValues",
  get: ({ get }) => {
    return [...get(allOreCardsState)]
      .filter(({ type }) => type === "pfps")
      .filter(({ traits }) => !isNaN(Number(traits["RARITY SCORE"])))
      .map(({ traits }) => parseFloat(traits["RARITY SCORE"] || ""))
      .sort((a, b) => a - b)
  },
})

const allOreCardsPFPsRarityScoreMinValue = selector<number>({
  key: "AllOreCardsPFPsRarityScoreMinValue",
  get: ({ get }) => {
    return get(allOreCardsPFPsRarityScoreValues)[0]
  },
})

const allOreCardsPFPsRarityScoreMaxValue = selector<number>({
  key: "AllOreCardsPFPsRarityScoreMaxValue",
  get: ({ get }) => {
    const allScoreValues = get(allOreCardsPFPsRarityScoreValues)
    return allScoreValues[allScoreValues.length - 1]
  },
})

const allCurrentOreCardsState = selector<Card[]>({
  key: "AllCurrentOreCards",
  get: ({ get }) => {
    let allCards = [...get(allFilterableOreCardsState)]
    const currentFilter = get(allOreCardsCurrentFilterState)
    const currentSort = get(allOreCardsSortState).trim()

    currentFilter.forEach((values, trait) => {
      allCards = allCards.filter(({ traits }) => {
        const value = traits[trait]
        if (!value) {
          return false
        }

        if (trait.toUpperCase() === "RARITY SCORE") {
          const maxRarityScore = get(allOreCardsPFPsRarityScoreMaxValue)
          const [minValue, maxValue] = [...values].map(v => parseFloat(v))

          // This is a specialty to ensure all boss level rarity scores (value === "???.??") are included if
          // the upper bound is not filtered.
          if (maxValue === maxRarityScore && isNaN(Number(value))) {
            return true
          }

          return parseFloat(value) >= minValue && parseFloat(value) <= maxValue
        }

        return values.has(value)
      })
    })

    allCards.sort((a, b) => {
      if (a.sort.dir === "ASC") {
        if (a.sort.type === "string") {
          if (a.sort.value > b.sort.value) {
            return 1
          }

          if (a.sort.value < b.sort.value) {
            return -1
          }

          return 0
        }

        return Number(a.sort.value) - Number(b.sort.value)
      }

      if (a.sort.type === "string") {
        if (a.sort.value > b.sort.value) {
          return -1
        }

        if (a.sort.value < b.sort.value) {
          return 1
        }

        return 0
      }

      return Number(b.sort.value) - Number(a.sort.value)
    })

    if (currentSort.length) {
      allCards.sort((a, b) => {
        const aTraitValue = a.traits[currentSort]
        const bTraitValue = b.traits[currentSort]

        if (!aTraitValue || !bTraitValue) {
          return -1
        }

        if (aTraitValue > bTraitValue) {
          return 1
        }

        if (aTraitValue < bTraitValue) {
          return -1
        }

        return 0
      })
    }

    return allCards
  },
})

const allOreCardsTraitsState = selector({
  key: "AllOreCardsTraits",
  get: ({ get }) => {
    const allCards = get(allOreCardsState).filter(
      ({ type }) => type === get(allOreCardsCurrentTypeState)
    )
    const allTraits = new Map()

    allCards.forEach(({ traits }) => {
      Object.keys(traits).forEach(name => {
        const value = traits[name]
        if (!value) {
          return
        }

        if (!allTraits.has(name)) {
          allTraits.set(name, new Map())
        }

        const currentTrait = allTraits.get(name)
        if (!currentTrait.has(value)) {
          currentTrait.set(value, 1)
        } else {
          currentTrait.set(value, currentTrait.get(value) + 1)
        }
      })
    })
    ;[...allTraits].map(([name, currentTrait]) => {
      allTraits.set(
        name,
        new Map(
          [...currentTrait].sort(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([aName, aCount], [bName, bCount]) => bCount - aCount
          )
        )
      )
    })

    return allTraits
  },
})

const allOreCardsDefaultTraitState = selector({
  key: "AllOreCardsDefaultTrait",
  get: ({ get }) => {
    const traits = get(allOreCardsTraitsState)
    if (!traits.size) {
      return ""
    }

    const filteredTraits = get(allOreCardsCurrentFilterState)
    if (filteredTraits.size > 0) {
      const [firstFilteredTrait] = [...filteredTraits][0]

      return firstFilteredTrait
    }

    const [defaultTrait] = [...traits][0]

    return defaultTrait
  },
})

export {
  allOreCardsState,
  allOreCardsTypesState,
  allOreCardsCurrentTypeState,
  allOreCardsCurrentCardState,
  allCurrentOreCardsState,
  allOreCardsSearchState,
  allOreCardsSortState,
  allOreCardsTraitsState,
  allOreCardsCurrentFilterState,
  allOreCardsCurrentFiltersCountState,
  allOreCardsDefaultTraitState,
  allOreCardsPFPsRarityScoreMinValue,
  allOreCardsPFPsRarityScoreMaxValue,
}
